<template>
  <div class="CVPool">
    <div class="CVPool-content">
      <div class="serach_box">
        <a-form layout="inline" style="width:80%;">
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="resume_source"
              placeholder="状态"
            >
              <!-- <a-select-option v-for="item in resume_source_arr" value="jack">Jack</a-select-option> -->
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
              <a-select-option value="Yiminghe">yiminghe</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-tree-select
              style="min-width: 220px;max-width:360px"
              :treeData="posts_type_arr"
              :value="posts_type[0] ? posts_type : undefined"
              @change="onChangeTree"
              treeNodeLabelProp="title"
              treeCheckable
              :showCheckedStrategy="SHOW_PARENT"
              placeholder="请选择岗位"
            />
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 150px"
              v-model="resume_source"
              placeholder="来源"
            >
              <a-select-option
                v-for="(item, k) in resume_source_arr"
                :key="k"
                :value="item.FieldValue"
                >{{ item.FieldName }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 120px"
              v-model="education_type"
              placeholder="学历"
            >
              <a-select-option
                v-for="(item, k) in education_type_arr"
                :key="k"
                :value="item.FieldValue"
                >{{ item.FieldName }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" placeholder="紧急程度">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
              <a-select-option value="Yiminghe">yiminghe</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input
              placeholder="请输入"
              style="width:200px;"
              v-model="classify_name"
            />
          </a-form-item>
          <a-form-item>
            <a-button style="margin-right:20px" @click="resetBtn()"
              >重置</a-button
            >
            <a-button type="primary" @click="serachBtn()">查询</a-button>
          </a-form-item>
        </a-form>
        <router-link
          tag="div"
          class="map-position ant-menu-item-selected"
          to="/jobStatistics"
        >
          <a-icon type="login" style="font-size:18px;vertical-align: middle;" />
          添加简历
        </router-link>
      </div>
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        :customRow="rowClick"
        :scroll="{ x: '150%' }"
        style="margin-top:10px;border:1px solid #efefef"
      >
        <template slot="action" slot-scope="columnsInfo">
          <a-tooltip placement="left" overlayClassName="tooltipBg">
            <template slot="title">
              <div style="padding: 0px 4px;">
                <p class="hoverColor" @click="stopFn(columnsInfo)">
                  停止招聘{{ columnsInfo.key }}
                </p>
                <p
                  class="hoverColor"
                  @click="delFn(columnsInfo)"
                  style="margin-top:2px;"
                >
                  删除{{ columnsInfo.age }}
                </p>
              </div>
            </template>
            <a-icon type="ellipsis" style="font-size: 28px;font-weight: 800;" />
          </a-tooltip>
        </template>
        <template slot="footer" slot-scope="currentPageData">
          <div style="font-weight:bold;">
            <span style="margin-right:20px;"> 合计:</span> {{ '36' }}
          </div>
        </template>
      </a-table>
      <div style="margin: 20px 0;float:right">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="total"
          :pageSize="page"
          @change="pageChange"
        />
      </div>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
  </div>
</template>
<script>
const columns = [
  {
    title: 'Full Name',
    width: 180,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left'
  },
  { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
  { title: 'Column 1', dataIndex: 'address', key: '1' },
  { title: 'Column 2', dataIndex: 'address', key: '2' },
  { title: 'Column 3', dataIndex: 'address', key: '3' },
  { title: 'Column 4', dataIndex: 'address', key: '4' },
  { title: 'Column 5', dataIndex: 'address', key: '5' },
  { title: 'Column 6', dataIndex: 'address', key: '6' },
  { title: 'Column 7', dataIndex: 'address', key: '7' },
  { title: 'Column 8', dataIndex: 'address', key: '8' },
  {
    title: 'Action',
    key: 'operation',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' }
  }
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York Park'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    address: 'London Park'
  }
]
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import {
  __dateTransation, //插件时间格式化公共方法
  __persionnal_Status,
  __arr_experience,
  __arr_Education,
  axios
} from '@/assets/js/public_ren'
export default {
  name: 'CVPool',
  data () {
    return {
      SHOW_PARENT,
      spinning: false,
      clientH: '400px',
      //table
      data,
      columns,
      //搜索
      posts_type_arr: [], //岗位
      posts_type: [],
      education_type_arr: [], //学历
      education_type: undefined,
      // pressing_type_arr: [],//
      // pressing_type: undefined,
      resume_source_arr: [], //来源
      resume_source: undefined,
      classify_name: '', //input输入框
      // startDate: '',
      // endDate: '',
      page: 5, //每页5条
      index: 1, //页码
      total: 1 //总数
    }
  },
  mounted () {
    if (!sessionStorage.getItem('posts_type_arr')) {
      this.$axios.get(8007, {}, res => {
        if (res.data.code == 1) {
          this.posts_type_arr = res.data.data
          window.sessionStorage.setItem(
            'posts_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取岗位数据失败!')
        }
      })
    } else {
      this.posts_type_arr = JSON.parse(
        window.sessionStorage.getItem('posts_type_arr')
      )
    }
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 248 + 'px'

    if (!sessionStorage.getItem('education_type_arr')) {
      this.$axios.get(9944, {}, res => {
        if (res.data.code == 1) {
          this.education_type_arr = res.data.data
          window.sessionStorage.setItem(
            'education_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取学历数据失败!')
        }
      })
    } else {
      this.education_type_arr = JSON.parse(
        window.sessionStorage.getItem('education_type_arr')
      )
    }
    if (!sessionStorage.getItem('resume_source_arr')) {
      this.$axios.get(9943, {}, res => {
        if (res.data.code == 1) {
          this.resume_source_arr = res.data.data
          window.sessionStorage.setItem(
            'resume_source_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取简历来源失败!')
        }
      })
    } else {
      this.resume_source_arr = JSON.parse(
        window.sessionStorage.getItem('resume_source_arr')
      )
    }
    if (!sessionStorage.getItem('employ_status_arr')) {
      this.$axios.get(9943, {}, res => {
        if (res.data.code == 1) {
          this.resume_source_arr = res.data.data
          window.sessionStorage.setItem(
            'employ_status_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取简历来源失败!')
        }
      })
    } else {
      this.employ_status_arr = JSON.parse(
        window.sessionStorage.getItem('employ_status_arr')
      )
    }
  },
  methods: {
    //选择岗位
    onChangeTree (value) {
      console.log(value)
      this.posts_type = value
    },

    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn(
        this.index,
        this.page,
        this.resume_source,
        this.posts_type,
        this.pressing_type
      )
    },
    //重置
    resetBtn () {
      console.log(this.posts_type, this.resume_source, this.pressing_type)
      this.resume_source = undefined
      this.posts_type = ''
      this.pressing_type = undefined
      this.classify_name = ''
      this.index = 1
      // this.tableFn(1,this.page,this.classify_type,this.classify_name,);
    },
    //搜索按钮
    serachBtn () {
      if (!this.classify_type && !this.classify_name) {
        console.log('都为空不请求!')
        return false
      }
      this.tableFn(1, this.classify_type, this.classify_name)
    },
    // 点击table
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            console.log(record, index)
          }
        }
      }
    },
    //停止招聘
    stopFn (val) {
      console.log(val)
    },
    delFn (val) {
      console.log(val)
    },
    //请求ajax
    tableFn (
      index = 1,
      education_type = '',
      resume_source = '',
      posts_type = '',
      pressing_type = '',
      selectVal = '',
      startDate = '',
      endDate = ''
    ) {
      console.log(
        index,
        education_type,
        resume_source,
        posts_type,
        pressing_type,
        selectVal,
        startDate,
        endDate
      )
      let that = this
      let obj = {
        ResumeType: 0,
        Position: that.posts_type,
        WorkPlace: ''
      }
      that.$axios.get(9941, obj, res => {
        console.log(res.data)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.CVPool {
  margin-top: 21px;
  .CVPool-content {
    width: 100%;
    .serach_box {
      position: relative;
      .map-position {
        position: absolute;
        top: 4px;
        right: 0px;
      }
      .item-label {
        margin-right: 12px;
        margin-bottom: 12px;
        width: 160px;
      }
    }
  }
}
</style>
